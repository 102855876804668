<script lang="ts">
import type { PropType } from "vue";
import MBtn from "@mono/ui/src/components/MBtn.vue";
import type { TypedRouteLocationRaw } from "@typed-router";

export default defineComponent({
  extends: MBtn,
  props: {
    route: {
      type: Object as PropType<TypedRouteLocationRaw>,
      required: true,
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <!-- @vue-expect-error -->
  <NuxtLink
    :to="route"
    style="text-decoration: none; color: inherit"
    :class="{
      'pointer-events-none': disabled,
    }"
    :target="openInNewTab ? '_blank' : undefined"
    :rel="openInNewTab ? 'noopener noreferrer' : undefined"
  >
    <MBtn v-bind="$props">
      <slot />
    </MBtn>
  </NuxtLink>
</template>
